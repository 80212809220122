import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes'

import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <CookiesProvider>
    <Routes />
  </CookiesProvider>,
  document.getElementById('root')
);

