import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';

// import Splash from './pages/Splash/index'
import './utils/styles.css';
const Home = lazy(() => import('./pages/Home/index'))
const BuscarVeiculo = React.lazy(() => import('./pages/BuscarVeiculo/index.js'))
const BateriasCompativeis = React.lazy(() => import('./pages/BateriasCompativeis/bateriasCompativeis.js'))
const VeiculosCompativeis = React.lazy(() => import('./pages/BateriasCompativeis/veiculosCompativeis.js'))
const BateriasDaMarca = React.lazy(() => import('./pages/BateriasCompativeis/bateriasDaMarca.js'))
const InfoLoja = React.lazy(() => import('./pages/InfoLoja/index.js'))
const Politica = React.lazy(() => import('./pages/Politica/index.js'))
export default function Routes() {
  const [states, setStates] = useState({})
  function Loader() {
    return (
      <div className='container-loader'>
        <div className="lds-ring">
          <div> </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Home
                states={states} setStates={setStates}
              />
            </Suspense>}
        />
        <Route path="/buscar-veiculo"
          element={
            <Suspense fallback={<Loader />}>
              <BuscarVeiculo
                states={states} setStates={setStates}
              />
            </Suspense>}
        />
        <Route path="/baterias-compativeis"
          element={
            <Suspense fallback={<Loader />}>
              <BateriasCompativeis
                states={states} setStates={setStates}
              />
            </Suspense>}
        />
        <Route path="/veiculos-compativeis"
          element={
            <Suspense fallback={<Loader />}>
              <VeiculosCompativeis
                states={states} setStates={setStates}
              />
            </Suspense>}
        />
        <Route path="/baterias-da-marca"
          element={
            <Suspense fallback={<Loader />}>
              <BateriasDaMarca
                states={states} setStates={setStates}
              />
            </Suspense>}
        />
        <Route path="/info-loja"
          element={
            <Suspense fallback={<Loader />}>
              <InfoLoja
                states={states} setStates={setStates}
              />
            </Suspense>}
        />
        <Route path="/politica"
          element={
            <Suspense fallback={<Loader />}>
              <Politica />
            </Suspense>
          }
        />
      </Switch>
    </BrowserRouter>
  )
}